import React, { useState, useEffect } from 'react';
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import LayoutSolutionsProvider from "../components/layout-solutions-provider"
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import Container from "../components/pages/container/container"
import ContainerFluid from "../components/pages/container/container-fluid"

import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"

type DataProps = {
  data: IndexQueryQuery
  location: Location
}

const SolutionsProviderPage: React.FC<PageProps<DataProps>> = ({ location }) => {

    const data = useStaticQuery(
        graphql`
          query {
            hero_background: file(relativePath: { eq: "solutions-provider-bg.png" }) {
              childImageSharp {
                gatsbyImageData(
                  quality:100
                  width:1400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            logo_hero: file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                  gatsbyImageData(
                    width: 200
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
            }
            dots_bg1: file(relativePath: { eq: "dots-bg1.png" }) {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
            }
            dots_bg2: file(relativePath: { eq: "dots-bg2.png" }) {
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
            }
        }
        `
    )

    //declare image Data
    const hero_background = getImage(data.hero_background)
    const logo_hero = getImage(data.logo_hero)
    const dots_bg1 = getImage(data.dots_bg1)
    const dots_bg2 = getImage(data.dots_bg2)

    //for bg image
    const hero_bg = convertToBgImage(hero_background)

  return (
    <>
        <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </Helmet>
        <LayoutSolutionsProvider>
            <Seo
            title="Solutions Provider"
            />
            <section id="solutions-provider">
                <div className="container">
                    <div className="row">
                        <GatsbyImage
                            image={dots_bg1}
                            alt=""
                            className="dots-bg1"
                        />
                        <GatsbyImage
                            image={dots_bg2}
                            alt=""
                            className="dots-bg2"
                        />
                        <div className="col-lg-12">
                            <Link to="/">
                                <GatsbyImage
                                    image={logo_hero}
                                    alt="Logo"
                                    className="logo-hero"
                                />
                            </Link>
                            <div className="row">
                                <div className="col-lg-6 text-right left-col-btn">
                                    <a href="https://peer-club-backend.web.app/login?action=login"><button className="login-btn">LOGIN</button></a>
                                </div>
                                <div className="col-lg-6 text-left right-col-btn">
                                    <Link to="/origins"><button className="origins-btn">ORIGINS</button></Link>
                                </div>
                            </div>
                            <div className="solutions-provider-container text-center">
                                <div className="background-white">
                                    <h1>Discover the Power of The Peer Club with a Complimentary Discovery Membership. </h1>
                                </div>
                                <p>Our discovery membership allows you to join The Peer Club for free and sample how the Club supports peers and helps Solution Providers.</p>
                                <p>You simply register your details, tell us about your solutions and describe the profile of the peers you would like to meet.</p>
                                <p>We then set up an information feed which alerts you when meeting content is directly relevant to your solution.</p>
                                <p>If you see a meeting that you really want to be part of then you can upgrade to Knowledge Partner status and become a key player in the development of the agenda, peer participant profiles and enjoy full participation across all email threads.</p>
                                <p className="mb-0">You don’t have to upgrade to Knowledge Partner, in fact you can remain on our discovery programme receiving alerts on meetings in your sector for as long as you like.</p>
                                <div className="row">
                                    <div className="col-lg-6 text-center">
                                        <p className="discover-p">(I’d like to explore the Club without commitment)</p>
                                        <a href="#"><button className="discover-btn">DISCOVER SIGN UP</button></a>
                                    </div>
                                    <div className="col-lg-6 text-center">
                                        <p className="knowledge-p">(No, I’d like to better understand the costs and benefits of upgrading to the Knowledge Partner level in the Club.)</p>
                                        <Link to="#"><button className="knowledge-btn">KNOWLEDGE PARTNER</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </LayoutSolutionsProvider>
    </>
  )
}

export default SolutionsProviderPage
