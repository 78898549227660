import React, { useState, useEffect, useRef } from 'react'
import { PageProps, Link, graphql, useStaticQuery, navigate } from "gatsby"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import "./style.scss"
import Logo from "images/logo.png"
import MobileDropdown from 'components/navigation/mobile-navigation.tsx'

type DataProps = {
  site: {
    buildTime: string
  }
  location:Location
}

const Header: React.FC<PageProps<DataProps>> = ({ path }) => {
  const data = useStaticQuery(
    graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `
  )

  const logo = getImage(data.logo);


  return (
    <nav className="navbar navbar-expand navbar-light flex-column flex-md-row" id="main-navigation">
      <div className="container">
        <div className="hidden-md-up">
          <MobileDropdown />
        </div>
        {/* <div className="col-lg-2">
          <Link className="text-center" to="/">
            <img src={Logo} id="logo" alt="peer club logo"/>
          </Link>

        </div> */}
        <div className="col-lg-12">
          <div className="navbar-nav-scroll hidden-md-down">
            <ul className="navbar-nav bd-navbar-nav justify-content-center">
              <li
                // className={
                //   location.pathname === '/'
                //     ? 'nav-item active'
                //     : 'nav-item'
                // }
              >
                <Link to="#" className="nav-link">
                  ABOUT
                </Link>
              </li>
              <li
                // className={
                //   location.pathname === '/'
                //     ? 'nav-item active'
                //     : 'nav-item'
                // }
              >
              <Link to="#" className="nav-link">
                FREE DISCOVERY MEMBER
              </Link>
                </li>
                  <li
                //     className={
                //     location.pathname === '/'
                //       ? 'nav-item active'
                //       : 'nav-item'
                // }
              >
              <Link to="#" className="nav-link">
                KNOWLEDGE PARTNER MEMBER
              </Link>
                </li>
                <li
                //     className={
                //     location.pathname === '/'
                //       ? 'nav-item active'
                //       : 'nav-item'
                // }
              >
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex" />
      </div>
    </nav>
  )

}

export default Header
